.border-gradient {
    border: 5px solid;
    border-image-slice: 1;
    border-color: #D00000;
    border-image: linear-gradient(var(--angle), #39603B, #d53a9d, #D00000) 1;
    animation: 2s rotate linear infinite;
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }